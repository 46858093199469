import CustomHTMLElement from './customHtmlElement';

export default class Course extends CustomHTMLElement {
	constructor(properties = {}) {
		super({
			title: '',
			author: '',
			likes: 0,
			url: '',
			tags: [],
			date: '',
			image: '',
			isVideo: false,
			labelATitle: 'Ga naar de pagina ',
			originalPrice: '',
			discountedPrice: '',
		});
		this.setProperties(properties);

		this._template = require('./templates/course.handlebars');
	}

	setTitle(title = '') {
		this._property('title', `${title}`);
	}

	set title(title = '') {
		this.setTitle(title);
	}

	get title() {
		return this._property('title');
	}

	setAuthor(author = '') {
		this._property('author', `${author}`);
	}

	set author(author = '') {
		this.setAuthor(author);
	}

	get author() {
		return this._property('author');
	}

	setUrl(url = '') {
		this._property('url', `${url}`);
	}

	set url(url = '') {
		this.setUrl(url);
	}

	get url() {
		return this._property('url');
	}

	setDate(date = '') {
		this._property('date', `${date}`);
	}

	set date(date = '') {
		this.setDate(date);
	}

	get date() {
		return this._property('date');
	}

	setImage(image = '') {
		this._property('image', `${image}`);
	}

	set image(image = '') {
		this.setImage(image);
	}

	get image() {
		return this._property('image');
	}

	setIsVideo(isVideo = false) {
		this._property('isVideo', !!isVideo);
	}

	set isVideo(isVideo = false) {
		this.setIsVideo(isVideo);
	}

	get isVideo() {
		return this._property('isVideo');
	}
	
	setLabelATitle(label = 'Ga naar de pagina ') {
		this._property('labelATitle', `${label}`);
	}

	set labelATitle(label = 'Ga naar de pagina ') {
		this.setLabelATitle(label);
	}

	get labelATitle() {
		return this._property('labelATitle');
	}

	setOriginalPrice(label = '') {
		this._property('originalPrice', `${label}`);
	}

	set originalPrice(label = '') {
		this.setOriginalPrice(label);
	}

	get originalPrice() {
		return this._property('originalPrice');
	}

	setDiscountedPrice(label = '') {
		this._property('discountedPrice', `${label}`);
	}

	set discountedPrice(label = '') {
		this.setDiscountedPrice(label);
	}

	get discountedPrice() {
		return this._property('discountedPrice');
	}

	setIsDiscounted(isDiscounted = false) {
		this._property('isDiscounted', !!isDiscounted);
	}

	set isDiscounted(isDiscounted = false) {
		this.setIsDiscounted(isDiscounted);
	}

	get isDiscounted() {
		return this._property('isDiscounted');
	}
}