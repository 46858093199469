import CustomHTMLElement from './customHtmlElement';

export default class Project extends CustomHTMLElement {
	constructor(properties = {}) {
		super({
			title: '',
			author: '',
			url: '',
			date: '',
			image: '',
			labelATitle: 'Ga naar de pagina '
		});
		this.setProperties(properties);

		this._template = require('./templates/project.handlebars');
	}

	setTitle(title = '') {
		this._property('title', `${title}`);
	}

	set title(title = '') {
		this.setTitle(title);
	}

	get title() {
		return this._property('title');
	}

	setAuthor(author = '') {
		this._property('author', `${author}`);
	}

	set author(author = '') {
		this.setAuthor(author);
	}

	get author() {
		return this._property('author');
	}

	setUrl(url = '') {
		this._property('url', `${url}`);
	}

	set url(url = '') {
		this.setUrl(url);
	}

	get url() {
		return this._property('url');
	}

	setDate(date = '') {
		this._property('date', `${date}`);
	}

	set date(date = '') {
		this.setDate(date);
	}

	get date() {
		return this._property('date');
	}

	setImage(image = '') {
		this._property('image', `${image}`);
	}

	set image(image = '') {
		this.setImage(image);
	}

	get image() {
		return this._property('image');
	}
	
	setLabelATitle(label = 'Ga naar de pagina ') {
		this._property('labelATitle', `${label}`);
	}

	set labelATitle(label = 'Ga naar de pagina ') {
		this.setLabelATitle(label);
	}

	get labelATitle() {
		return this._property('labelATitle');
	}

	setViewProject(label = 'bekijk project') {
		this._property('viewProject', `${label}`);
	}

	set viewProject(label = 'bekijk project') {
		this.setViewProject(label);
	}

	get viewProject() {
		return this._property('viewProject');
	}
}