export default class SliderController {
    constructor() {
        this.selector = 'ul.slider';
        this.sliders = [];

        if (document.querySelectorAll(this.selector).length) {
            this.init();
        }

        if (document.body.classList.contains('shopproductdetailpage')) {
            this.initDoubleSlider("product");
        }

        if(document.body.classList.contains('projectdetailpage'))
        {
            this.initDoubleSlider("project");
        }

        if(document.body.classList.contains('coursedetailpage'))
        {
            this.initDoubleSlider("course");
        }
    }

    init() {
        // import(/* webpackChunkName: "modules/carousel" */ 'slick-carousel').then(module => {
            // Slick's available in jQuery from here on

            document.querySelectorAll(this.selector).forEach(slider => {
                if (slider.classList.contains('productSlider')) {
                    this.sliders.push($(slider).slick({
                        dots: true,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 2000,
                        slidesToShow: 1,
                        fade: true,
                        prevArrow: document.querySelector('.previousContainer a'),
                        nextArrow: document.querySelector('.nextContainer a'),
                        appendDots: document.querySelector('.dotsContainer'),
                    }));
                } else {
                    this.sliders.push($(slider).slick({
                        dots: true,
                        slidesPerRow: 4,
                        mobileFirst: true,
                        infinite: false,
                        prevArrow: '.sliderControls .prev button',
                        appendDots: '.sliderControls .dots',
                        nextArrow: '.sliderControls .next button',
                        responsive: [{
                            breakpoint: 768,
                            settings: {
                                slidesPerRow: 4,
                                rows: 1
                            }
                        }]
                    }));
                }
            });
        // });
    }

    initDoubleSlider(selectorName) {
        // Special case due to linking two sliders
        // import(/* webpackChunkName: "modules/carousel" */ 'slick-carousel').then(module => {

            const $main = $(`.${selectorName}ImageSliderMain`);
            const $small = $(`.${selectorName}ImagesSliderSmall`);

            if ($main.length && $small.length) {
                console.log({
                    mainSlider: $main.children('ul'),
                    mainPrevious: $main.find('.previousContainer a'),
                    
                })

                const $mainSlider = $main.children('ul');
                const $mainPrevious = $main.find('.previousContainer a');
                const $mainNext = $main.find('.nextContainer a');

                const $smallSlider = $small.children('ul');
                const $smallPrevious = $small.find('.previousContainer a');
                const $smallNext = $small.find('.nextContainer a');

                console.log($mainNext[0]);

                this.sliders.push($mainSlider.slick({
                    prevArrow: $mainPrevious,
                    nextArrow: $mainNext,
                    asNavFor: $smallSlider,
                    infinite: false,
                }));

                this.sliders.push($smallSlider.slick({
                    prevArrow: $smallPrevious,
                    nextArrow: $smallNext,
                    asNavFor: $mainSlider,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    focusOnSelect: true,
                    infinite: false,
                }));
            }

        // });
    }
}