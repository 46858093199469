import 'simplebar';

export default class LandingController {
    constructor() {
        this.init();
    }

    init() {
        this.setEventListeners();
    }

    setEventListeners() {
        document.querySelector('.actions a.button').addEventListener('click', () => {
            document.querySelector('.popup.newsletterPopup').classList.add('active');
            this.blurOn();
            if (window.innerWidth >= 768) {
                document.querySelector('form.newsletterForm input[name="email"]').focus();
            }
        });

        document.querySelector('.popup.newsletterPopup a.close').addEventListener('click', () => {
            document.querySelector('.popup.newsletterPopup').classList.remove('active');
            this.blurOff();

            setTimeout(() => {
                this.resetNewsletterPopup();
            }, 1000);
        });

        document.querySelector('a.moreInfo').addEventListener('click', () => {
            document.querySelector('.popup.moreInfoPopup').classList.add('active');
            this.blurOn();
        });

        document.querySelector('.popup.moreInfoPopup a.close').addEventListener('click', () => {
            document.querySelector('.popup.moreInfoPopup').classList.remove('active');
            this.blurOff();

            setTimeout(() => {
                document.querySelector('.popup.moreInfoPopup .tabHeaders .tabHeader').querySelector('a, button').click();
            }, 1000);
        });

        document.querySelector('.popup.moreInfoPopup.tabs').addEventListener('select', e => {
            document.querySelector('.popup.moreInfoPopup .content').scrollTop = 0;
            window.slimmage.cr();
        });

        document.querySelector('form.newsletterForm').addEventListener('submit', e => {
            e.preventDefault();
            
            const form = e.target;
            const emailInput = form.querySelector('input[name="email"]');
            if (emailInput.value.trim() == '') {
                this.newsletterShowError();
            } else if (!(/\S+@\S+\.\S+/.test(emailInput.value.toLowerCase()))) {
                this.newsletterShowError();
            } else {
                this.newsletterHideError();
                setTimeout(() => {
                    const el = document.querySelector('.popup.newsletterPopup .content');
                    let formData = new FormData();
                    formData.append('email', form.querySelector('input[name="email"]').value);
                    formData.append('language', (form.querySelector('input[name="language"]').value != '' ? form.querySelector('input[name="language"]').value : 'nl'));

                    el.classList.remove('start');
                    el.classList.add('loading');

                    fetch(form.getAttribute('action') + '?' + $(form).serialize(), {
                        body: formData,
                        method: 'post'
                    }).then(response => {
                        return response.text();
                    }).then(result => {
                        console.log(result);

                        el.classList.remove('loading');
                        if (result.toString() == '"OK"' || result.toString() == 'OK') {
                            el.classList.add('success');
                            window.slimmage.cr();
                        } else {
                            el.classList.add('error');
                        }
                    });
                }, 200);
                
            }
            
            /* const data = $(form).serialize();
            const mailChimpUrl = form.getAttribute('action');

            console.log(data);
            console.log(mailChimpUrl);

            fetch(mailChimpUrl + '&c=?&' + data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'get',
                mode: 'no-cors',
            }).then(response => {
                window.responseTest = response;
                return response.json();
            }).then(result => {
                console.log(result);
            }); */

            return false;

            /* const $form = $(e.target);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                cache       : false,
                dataType    : 'json',
                contentType: "application/json; charset=utf-8",
                error       : function(err) { alert("Could not connect to the registration server. Please try again later."); },
                success     : function(data) {
                    if (data.result != "success") {
                        // Something went wrong, do something to notify the user. maybe alert(data.msg);
                    } else {
                        // It worked, carry on...
                    }
                }
            }); */
        });

       /*  document.querySelector('.popup.newsletterPopup a.button').addEventListener('click', () => {
            const el = document.querySelector('.popup.newsletterPopup .content.start');

            // TODO: Replace with actual MailChimp link
            setTimeout(() => {
                el.classList.remove('start');
                el.classList.add('success');

                window.slimmage.cr();
            }, 500);
        }); */

        document.addEventListener('keydown', e => {
            if (e.key && e.key.toLowerCase() == 'escape') {
                document.querySelectorAll('.popup').forEach(popup => popup.classList.remove('active'));
                this.blurOff();
                
                setTimeout(() => {
                    this.resetNewsletterPopup();
                }, 1000);
            }
        });
    }

    blurOn() {
        document.querySelectorAll('header, footer, section#content, section#background').forEach(el => {
            el.classList.add('blurred');
            el.classList.add('noanimate');
        });
    }

    blurOff() {
        document.querySelectorAll('header, footer, section#content, section#background').forEach(el => {
            el.classList.remove('blurred');
            setTimeout(() => {
                el.classList.remove('noanimate');
            }, 595);
        });
    }

    newsletterShowError() {
        const form = document.querySelector('form.newsletterForm');
        form.querySelector('input[name="email"]').classList.add('error');
        if (window.innerWidth >= 768) {
            form.querySelector('input[name="email"]').focus();
        }
    }

    newsletterHideError() {
        const form = document.querySelector('form.newsletterForm');
        form.querySelector('input[name="email"]').classList.remove('error');
    }

    resetNewsletterPopup() {
        const el = document.querySelector('.popup.newsletterPopup .content');
        document.querySelector('form.newsletterForm input[name="email"]').value = '';
        document.querySelector('form.newsletterForm input[name="email"]').classList.remove('error');
        el.classList.remove('loading');
        el.classList.remove('error');
        el.classList.remove('success');
        el.classList.add('start');
    }
}