export default class LoginController {
    constructor(autoinit = true) {
        this.firebaseApp = null;
        if (autoinit) {
            this.init();
        }
    }

    init() {
        if (document.querySelector("div.firebaseEnabled") !== null) {
            import( /* webpackChunkName: "modules/firebase" */ 'firebase/app').then(module => {

                window.firebase = module.default;
                import( /* webpackChunkName: "modules/firebaseAuth" */ 'firebase/firebase-auth').then(() => {

                    var firebaseConfig = {
                        apiKey: "AIzaSyD7Dj7q44Pqx5J_br7Nuk179-bc06Ekf3s",
                        authDomain: "stribe-267114.firebaseapp.com",
                        databaseURL: "https://stribe-267114.firebaseio.com",
                        projectId: "stribe-267114",
                        storageBucket: "stribe-267114.appspot.com",
                        messagingSenderId: "998866450354",
                        appId: "1:998866450354:web:e7073ac24db7b496d916ab",
                        measurementId: "G-JS4M7QE25G"
                    };

                    this.firebaseApp = window.firebase.initializeApp(firebaseConfig);
                    this.addEventListeners();
                });


            });
        }
    }

    addEventListeners() {
        if (document.querySelector('.btn-google') != null) {
            document.querySelector('.btn-google').addEventListener('click', () => {
                var provider = new window.firebase.auth.GoogleAuthProvider();

                firebase.auth().signInWithPopup(provider).then(function (result) {
                    firebase.auth().currentUser.getIdToken( /* forceRefresh */ true).then(function (idToken) {
                        // Send token to your backend via HTTPS
                        // ...
                        var apiCall = fetch(`/umbraco/surface/umbracoidentityaccount/googlesignin`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                                // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body: JSON.stringify({
                                token: idToken
                            })
                        });
                        apiCall.then(response => {
                            if (response.status == 401) {
                                document.querySelector('.firebaseEnabled .field-validation-error').classList.add('visible');
                                setTimeout(() => {
                                document.querySelector('.firebaseEnabled .field-validation-error').classList.remove('visible');
                                }, 3500);
                            } else if (response.status == 200) {
                                window.location.reload();
                            }
                        });

                    }).catch(function (error) {
                        document.querySelector('.firebaseEnabled .field-validation-error').classList.add('visible');
                        setTimeout(() => {
                            document.querySelector('.firebaseEnabled .field-validation-error').classList.remove('visible');
                            }, 3500);

                    });
                }).catch(function (error) {
                    document.querySelector('.firebaseEnabled .field-validation-error').classList.add('visible');
                    setTimeout(() => {
                        document.querySelector('.firebaseEnabled .field-validation-error').classList.remove('visible');
                        }, 3500);

                });


            });
        }
        if (document.querySelector('.btn-google-link') != null) {
            document.querySelector('.btn-google-link').addEventListener('click', () => {
                var provider = new window.firebase.auth.GoogleAuthProvider();

                firebase.auth().signInWithPopup(provider).then(function (result) {
                    firebase.auth().currentUser.getIdToken( /* forceRefresh */ true).then(function (idToken) {
                        // Send token to your backend via HTTPS
                        // ...
                        var apiCall = fetch(`/umbraco/surface/umbracoidentityaccount/linkgooglesignin`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                token: idToken
                            })
                        });
                        apiCall.then(response => {
                            if (response.status == 401) {
                                document.querySelector('.firebaseEnabled .field-validation-error').classList.add('visible');
                                setTimeout(() => {
                                    document.querySelector('.firebaseEnabled .field-validation-error').classList.remove('visible');
                                    }, 3500);
                            } else if (response.status == 200) {
                                window.location.reload();
                            }
                        });

                    }).catch(function (error) {
                        document.querySelector('.firebaseEnabled .field-validation-error').classList.add('visible');
                        setTimeout(() => {
                            document.querySelector('.firebaseEnabled .field-validation-error').classList.remove('visible');
                            }, 3500);
                    });
                }).catch(function (error) {
                    document.querySelector('.firebaseEnabled .field-validation-error').classList.add('visible');
                    setTimeout(() => {
                        document.querySelector('.firebaseEnabled .field-validation-error').classList.remove('visible');
                        }, 3500);
                });


            });
        }
    }
}