export default class Tabs {
    /**
     * @param {Element} container Container that includes both the tab headers and tab contents
     */
    constructor(container) {
        this.container = container;
        this.headers = null;
        this.contents = null;
        this.updateHash = false;
        this.watchHashUpdates = false;
        this.selectedIndex = 0;
    }

    /**
     * Initialize tab functionality
     */
    init() {
        if (this.container.querySelectorAll('.tabHeaders .tabHeader').length && this.container.querySelectorAll('.tabContents .tabContent').length) {
            this.headers = this.container.querySelectorAll('.tabHeaders .tabHeader');
            this.contents = this.container.querySelectorAll('.tabContents .tabContent');

            if (this.container.dataset.updateHash !== undefined && this.container.dataset.updateHash == 'true') {
                this.updateHash = true;
                this.watchHashUpdates = true;
            }

            this.setEventListeners();
            this.checkHash();
        } else {
            console.error(`Headers or contents are missing for tabs:`);
            console.error(this.container);
        }
    }

    /**
     * Listens for clicks/keyboard presses on the tab headers
     */
    setEventListeners() {
        this.headers.forEach((header, index) => {
            const link = this.getLinkFromHeader(header);

            // Mouse/Touch
            link.addEventListener('click', e => {
                e.preventDefault();
                
                if (!!link.getAttribute('aria-selected')) {
                    this.setAllTabsInactive();

                    // Decide if a class needs to be added to indicate if a user goes 'left or right' in the tab list
                    if (index > this.selectedIndex) {
                        this.contents[index].classList.add('right');
                    } else if (index < this.selectedIndex) {
                        this.contents[index].classList.add('left');
                    }

                    this.setTabActive(header);

                    this.selectedIndex = index;

                    if (this.updateHash) {
                        location.hash = link.getAttribute('aria-controls');
                    }
                }
            });

            // Keyboard
            link.addEventListener('keyup', e => {
                e.preventDefault();
                if (e.key.toLowerCase() == 'enter') {
                    // Emulate click
                    link.click();
                }
            })
        });

        if (this.watchHashUpdates) {
            window.addEventListener('hashchange', this.checkHash.bind(this));
        }
    }

    /**
     * Checks location.hash and updates if it matches a tab content ID
     */
    checkHash() {
        const hash = location.hash.replace('#', '');
        this.headers.forEach(header => {
            const link = this.getLinkFromHeader(header);
            if (link.getAttribute('aria-controls') == hash) {
                link.click();
            }
        })
    }

    /**
     * Removes active status from all tab headers and tab contents
     */
    setAllTabsInactive() {
        this.headers.forEach(header => {
            const link = this.getLinkFromHeader(header);
            link.setAttribute('aria-selected', 'false');
        });

        this.contents.forEach(content => {
            content.classList.remove('active');
            content.classList.remove('left');
            content.classList.remove('right');
        });
    }

    /**
     * Sets tab header and tab content to active based on the header's `aria-controls` attribute
     * @param {Element} header 
     */
    setTabActive(header) {
        const link = this.getLinkFromHeader(header);
        // Find matching tabContent
        let matchFound = false;
        this.contents.forEach(content => {
            if (content.id === link.getAttribute('aria-controls')) {
                matchFound = true;
                link.setAttribute('aria-selected', 'true');
                content.classList.add('active');

                this.container.dispatchEvent(new CustomEvent('select'));
            }
        });

        if (!matchFound) {
            console.error(`No matching tabContent found for tab ID ${link.getAttribute('aria-controls')}`);
        }
    }

    /**
     * Returns the a tag or button tag from a header
     * @param {Element} header
     * @returns {Element}
     */
    getLinkFromHeader(header) {
        return header.querySelector('a, button');
    }
}