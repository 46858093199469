import Cookies from 'js-cookie';

export default class AVGController {
	constructor() {
		this.avgPermissionsCookie = Cookies.getJSON('AVGPermissions');
		this.cookieBar = document.querySelector('#cookieBar');
		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			"event" : "ChangeCookieChoices",
			"Analytics" : this.AcceptedAnalyticsCookies,
			"Tracking" : this.AcceptedTrackingCookies
		});

		if(document.querySelector('form#cookieConfigure') !== null)
		{
			document.querySelector('input#Analytics').checked = this.AcceptedAnalyticsCookies;
			document.querySelector('input#Tracking').checked = this.AcceptedTrackingCookies;
		}

		this.LoadAVGScripts();
		this.CheckOpen();
		this.SetupButtonClicks();
	}

	get AcceptedFunctionalCookies() {
		if (this.avgPermissionsCookie === undefined) return false;

		return this.avgPermissionsCookie.Functional;
	}

	get AcceptedAnalyticsCookies() {
		if (this.avgPermissionsCookie === undefined) return true;

		return this.avgPermissionsCookie.Analytics;
	}

	get AcceptedTrackingCookies() {
		if (this.avgPermissionsCookie === undefined) return false;

		return this.avgPermissionsCookie.Tracking;
	}

	SetOrUpdatePermissionsCookie(functional, analytics, tracking) {
		this.avgPermissionsCookie = {
			Functional: functional,
			Analytics: analytics,
			Tracking: tracking
		};

		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			"Analytics" : this.AcceptedAnalyticsCookies,
			"Tracking" : this.AcceptedTrackingCookies
		});

		Cookies.set('AVGPermissions', this.avgPermissionsCookie, {
			expires: 99999
		});
	}

	SetupButtonClicks() {
		if (document.querySelector('a.button.agreeCookies') !== null) {
			document.querySelector('a.button.agreeCookies').addEventListener('click', this.AgreeCookies.bind(this));
		}
		if (document.querySelector('form#cookieConfigure') !== null) {
			document.querySelector('form#cookieConfigure').addEventListener('submit', (event) => {
				event.preventDefault();
				this.SavePreferences();
			});
		}
	}

	LoadAVGScripts() {
		document.querySelectorAll('noscript[data-gtm-tag]').forEach(noscript => {
			this.LoadGoogleTagManager(noscript.dataset.gtmTag);
		});
	}

	LoadGoogleTagManager(gtmcode) {
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js'
			});
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src =
				'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})
		(window, document, 'script', 'dataLayer', gtmcode);
	}

	AgreeCookies() {
		this.SetOrUpdatePermissionsCookie(true, true, true);
		location.reload();
	}

	CheckOpen() {
		if (this.avgPermissionsCookie === undefined) {
			if (document.querySelector('#cookieBar a.button.goConfigure') !== null) {
				if (document.querySelector('#cookieBar a.button.goConfigure').href !== document.location.toString()) {
					this.cookieBar.classList.add('open');
				}
			}
		}
	}

	SavePreferences() {
		this.SetOrUpdatePermissionsCookie(document.getElementById("Functional").checked, document.getElementById("Analytics").checked, document.getElementById("Tracking").checked);
		let saveConfirmation = document.querySelector('form#cookieConfigure .onSaveComplete');
		if (saveConfirmation !== null) {
			saveConfirmation.classList.add('success');
			setTimeout(function () {
				saveConfirmation.classList.remove('success');
			}, 1500);
		}
	}
}