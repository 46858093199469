export default class Accordeon {
    constructor(container) {
        this.container = container;
        this.items = [];
        this.activeClass = 'active';
        this.resetClass = 'reset';

        this.selectors = {
            header: '.header',
            content: '.content',
        };
    }

    init() {
        this.container.querySelectorAll('li').forEach(item => this.items.push(item));

        this.items.forEach(item => {
            const content = item.querySelector(this.selectors.content);
            if (item.classList.contains(this.activeClass)) {
                // content.style.height = `${content.scrollHeight}px`;
            } else {
                content.style.height = 0;
            }
        });

        // Timeout to check again, in case CSS loads slower
        setTimeout(() => {
            this.items.forEach(item => {
                const content = item.querySelector(this.selectors.content);
                if (item.classList.contains(this.activeClass)) {
                    content.style.height = `${content.scrollHeight}px`;
                }
            });
        }, 100);

        this.setEventListeners();
    }

    setEventListeners() {
        this.items.forEach(item => {
            /** @type HTMLElement */
            const header = item.querySelector(this.selectors.header);
            const content = item.querySelector(this.selectors.content);
            header.addEventListener('click', this.toggleItem.bind(this, item));

            let closeButton;
            if (closeButton = content.querySelector('a.close')) {
                closeButton.addEventListener('click', this.toggleItem.bind(this, item));
            }

            header.addEventListener('keydown', e => {
                switch (e.key.toLowerCase()) {
                    case ' ':
                    case 'enter':
                        this.toggleItem(item);
                        break;
                    case 'escape':
                    case 'esc':
                        this.inactive(item);
                        break;
                }
            }, true);
        });

        window.app.registerResizeEvent(this.resize.bind(this));
    }

    /**
     * 
     * @param {HTMLElement} item 
     */
    toggleItem(item) {
        

        if (item.classList.contains(this.activeClass)) {
            this.inactive(item);
        } else {
            this.active(item);
        }
    }

    active(item) {
        const header = item.querySelector(this.selectors.header);
        const content = item.querySelector(this.selectors.content);

        content.style.height = `${content.scrollHeight}px`;
        item.classList.add(this.activeClass);
        item.classList.remove(this.resetClass);

        header.setAttribute('aria-expanded', 'true');
    }

    inactive(item) {
        const header = item.querySelector(this.selectors.header);
        const content = item.querySelector(this.selectors.content);

        content.style.height = 0;
        item.classList.remove(this.activeClass);

        header.setAttribute('aria-expanded', 'false');
    }

    resize() {
        this.items.filter(item => !item.classList.contains(this.resetClass) && item.classList.contains(this.activeClass)).forEach(item => {
            const content = item.querySelector(this.selectors.content);
            content.style.height = 'auto';
            item.classList.add(this.resetClass);
        });
    }
}