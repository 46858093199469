export default class ShareController {
    /**
     * @param {boolean} autoInit If disabled, you have to call the init method after creating the object
     */
	constructor(autoInit = true) {
		this.selector = '.shareContainer';
		this.url = null;
		this.description = null;
        this.image = null;
        this.tagTitle = null;
		this.mobile = null;
		this.analytics = false;
		this.order = ['facebook', 'linkedin', 'twitter', 'email', 'whatsapp']; // If order should be different than default: fill with an array like this: ['facebook', 'linkedin'] etc.

		this.medias = {
			whatsapp: {
				name: 'WhatsApp',
				enabled: true,
				icon: `<svg class="sprite share_whatsapp"><use xlink:href="#share_whatsapp"/></svg>`,
				mobileOnly: true,
			},
			facebook: {
				name: 'Facebook',
				enabled: true,
				icon: `<svg class="sprite share_facebook"><use xlink:href="#share_facebook"/></svg>`,
				mobileOnly: false,
			},
			linkedin: {
				name: 'LinkedIn',
				enabled: true,
				icon: `<svg class="sprite share_linkedin"><use xlink:href="#share_linkedin"/></svg>`,
				mobileOnly: false,
			},
			twitter: {
				name: 'Twitter',
				enabled: true,
				icon: `<svg class="sprite share_twitter"><use xlink:href="#share_twitter"/></svg>`,
				mobileOnly: false,
			},
			email: {
				name: 'e-mail',
				enabled: true,
				icon: `<svg class="sprite share_email"><use xlink:href="#share_email"/></svg>`,
				mobileOnly: false,
			}
		};

		if (autoInit) {
			this.init();
		}
	}

	init() {
		if (document.querySelectorAll(this.selector).length) {
			if (this.url === null) {
				// Use current page
				this.url = window.location.href;
			}
	
			if (this.description === null) {
				this.description = this.autoObtainDescription();
			}
	
			if (this.mobile === null) {
				this.mobile = this.autoObtainMobile();
			}
	
			this.createShareButtons();
		}
	}

    /**
     * @returns {string} - String containing a description
     */
	autoObtainDescription() {
		let metaDescription = null;
		let ogDescription = null;

		let metaTags = document.head.getElementsByTagName('meta');

		for (var metaIndex = 0; metaIndex < metaTags.length; metaIndex++) {
			if (metaTags[metaIndex].getAttribute('property') === 'description') {
				metaDescription = metaTags[metaIndex].getAttribute('content');
			}
			if (metaTags[metaIndex].getAttribute('property') === 'og:description') {
				ogDescription = metaTags[metaIndex].getAttribute('content');
			}
		}

		if (metaDescription === null && ogDescription === null) {
			return '';
		} else if (ogDescription !== null) {
			return ogDescription;
		} else {
			return metaDescription;
		}
	}

	autoObtainMobile() {
		return /iPhone|Android|Blackberry/i.test(navigator.userAgent); // Crude but effective
	}

    // Social media specific methods
    

    /**
     * 
     * @param {string} media 
     */
    obtainTagTitle(media) {
        if (this.tagTitle === null) {
            return `Deel via ${this.medias[media].name}`;
        } else {
            return this.tagTitle.replace('MEDIA', this.medias[media].name);
        }
    }

	// Social media specific methods
	whatsapp() {
		return `https://wa.me?text=${encodeURIComponent(this.url)}`;
	}

	facebook() {
		return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(this.url)}`;
	}

	twitter() {
		let url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.url)}`;
		if (this.description !== '') {
			url += `&text=${encodeURIComponent(this.description)}`;
		} else if (document.title) {
            url += `&text=${encodeURIComponent(document.title)}`;
        }
		return url;
	}

	linkedin() {
		let url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(this.url)}&mini=true`;
		if (this.description !== '') {
			url += `&summary=${encodeURIComponent(this.description)}`;
		}
		return url;
	}

	email() {
		return `mailto:?body=${encodeURIComponent(this.url) + ' ' + this.description}&subject=Ik heb een interessante pagina gevonden: ${document.title}`;
	}

	// Final methods

	createShareButtons() {
		let list;
		if (this.order) {
			list = this.order;
		} else {
			list = Object.keys(this.medias);
		}

		document.querySelectorAll(this.selector).forEach(container => {
			list.forEach(media => {
				if (this.medias[media].enabled) {
					let renderIcon = true;
					if (this.medias[media].mobileOnly && !this.mobile) {
						renderIcon = false;
					}
	
					if (renderIcon) {
						let tag = document.createElement('a');
						tag.setAttribute('target', '_blank');
						tag.setAttribute('rel', 'noopener');
						tag.href = this[media]();
						tag.title = this.obtainTagTitle(media);
						tag.innerHTML = this.medias[media].icon;
	
						this.bindEvents(tag, media);
						container.appendChild(tag);
					}
				}
			});
		});
	}

	/**
	 * 
	 * @param {HTMLLinkElement} link - Link tag that needs to be listened to
	 * @param {string} media - Type of social media
	 */
	bindEvents(link, media) {
		link.addEventListener('click', e => {
			e.preventDefault();
			window.open(link.href, 'NewWindow', `menubar=no,toolbar=no,status=no,width=570,height=570,top=${(screen.height - 570) / 2},left=${(screen.width - 570) / 2}`);

			if (this.analytics) {
				if (window._gat) {
					//console.log(`window._gaq.push(['_trackEvent', 'Social share', '${media}', '${this.url}']);`);
					window._gaq.push(['_trackEvent', 'Social share', media, this.url]);
				} else if (window.ga) {
					//console.log(`window.ga('send', 'event', 'Social share', '${media}', '${this.url}');`);
					window.ga('send', 'event', 'Social share', media, this.url);
				}
			}

			return false;
		});
	}
}