import Toast from './toast';

export default class ToastController {
    constructor() {
        this.container = document.getElementById('content');
        this.defaultTimeout = 3500;
        this.animationDuration = 400;

        this.checkForToasts();
    }

    new(message = null, type = 'success', timeout = this.defaultTimeout) {
        const toast = new Toast(message, type);
        this.display(toast, timeout);
    }

    display(toast, timeout) {
        this.container.appendChild(toast);

        setTimeout(() => {
            toast.classList.add('disappearing');

            setTimeout(() => {
                toast.remove();
            }, this.animationDuration);
        }, timeout);
    }

    checkForToasts() {
        document.querySelectorAll('.showToast').forEach((el, i) => {
            const type = el.dataset.type || 'success';
            const text = el.innerHTML;
            el.remove();

            setTimeout(() => {
                this.new(text, type);
            }, (i * this.defaultTimeout + 500));
        });
    }
}