export default class PartlyHiddenController {
    constructor() {
        this.selectorClass = 'partlyHidden';
        this.showMoreText = 'show more';
        this.maxHeight = 400;

        this.init();
    }

    init() {
        document.querySelectorAll(`.${this.selectorClass}`).forEach(container => {
            let showMoreText = container.dataset.showText || this.showMoreText;
            let maxHeight = container.dataset.maxHeight ? parseInt(container.dataset.maxHeight) : false || this.maxHeight;

            if (container.clientHeight > maxHeight) {
                let showMoreLink = document.createElement('a');
                showMoreLink.classList.add('showMoreLink');
                showMoreLink.innerText = showMoreText;
                showMoreLink.addEventListener('click', e => {
                    showMoreLink.remove();
                    container.removeAttribute("style");
                    container.classList.remove(this.selectorClass);
                });

                container.parentNode.insertBefore(showMoreLink, container.nextSibling);
                container.style.maxHeight = `${maxHeight}px`;
            } else {
                container.classList.remove(this.selectorClass);
            }
        });
    }
}