import Accordeon from './accordeon.js';

export default class AccordeonController {
    constructor() {
        this.selector = 'ul.accordeon, ol.accordeon';
        this.accordeons = [];

        this.init();
    }

    init() {
        if (document.querySelectorAll(this.selector).length) {
            document.querySelectorAll(this.selector).forEach(accordeonElement => {
                let accordeon = new Accordeon(accordeonElement);
                this.accordeons.push(accordeon);

                accordeon.init();
            });
        }
    }
}