export default class Toast {
    constructor(message = null, type = 'success') {
        this.types = {
            success: {
                class: 'success',
                icon: 'icon_success',
            },
            error: {
                class: 'error',
                icon: 'icon_error',
            },
            warning: {
                class: 'warning',
                icon: 'icon_error',
            },
        };
        this.message = message;
        this.type = type;

        return this.init();
    }

    init() {
        if (!this.types[this.type]) {
            throw new Error(`Type ${type} not recognized`);
        }

        if (!this.message) {
            throw new Error(`Message missing`);
        }

        return this.getObject();
    }

    getObject() {
        let html = `<div class="toast ${this.types[this.type].class}">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <div class="content">
                            <svg class="sprite ${this.types[this.type].icon}">
                                <use xlink:href="#${this.types[this.type].icon}" />
                            </svg>
                            <span>${this.message}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;

        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.firstChild;
    }
}