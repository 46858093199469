import Filter from './filter';

// import Pager from './pager';

export default class FilterGroup {
    /**
     * 
     * @param {Filter[]} filters 
     * @param {HTMLElementList|HTMLElement[]} filterTargets 
     * @param {String} settings.filteredClass
     */
    constructor(filters = [], filterTargets = [], settings = {}) {
        this.filters = filters;
        this.filterTargets = filterTargets;

        this.filterData = {};

        // Settings
        this.settings = {
            filteredClass: 'filtered',
            api: false
        };

        this.loadSettings(settings);

        // /** @type {Pager} */
        // this.pager = null;
    }

    init() {
        this.setEventListeners();
        this.updateFilterData();
    }

    setEventListeners() {
        this.filters.forEach(filter => {
            filter.element.addEventListener('update', e => {
                this.updateFilterData();

                if (!this.settings.api) {
                    this.decideOnAllFilterTargets();
                }
            });
        });
    }

    updateFilterData() {
        this.filterData = {};
        this.filters.forEach(filter => {
            if (filter.selectedValues.length) {
                this.filterData[filter.name] = filter.selectedValues;
            }
        });
    }

    /**
     * @param {HTMLElement} filterTarget 
     */
    decideOnFilterTarget(filterTarget) {
        const filterResults = [];

        // Run target by all filters that have a value set
        this.filters.forEach(filter => {
            if (filter.selectedValues.length) {
                filterResults.push(filter.decide(filterTarget));
            }
        });

        // If any filter returned false, hide it. Otherwise, show it
        if (filterResults.includes(false)) {
            filterTarget.classList.add(this.settings.filteredClass);
        } else {
            filterTarget.classList.remove(this.settings.filteredClass);
        }
    }

    decideOnAllFilterTargets() {
        this.filterTargets.forEach(target => {
            this.decideOnFilterTarget(target);
        });
    }

    loadSettings(settings) {
        if (Object.keys(settings).length) {
            Object.keys(settings).forEach(setting => {
                if (this.settings[setting] !== undefined) {
                    this.settings[setting] = settings[setting];
                }
            });
        }
    }
}