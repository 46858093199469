/**
 * @typedef domList
 * @type {Object}
 * @property {HTMLElement} info
 * @property {HTMLElement} display
 * @property {HTMLElement} selection
 * @property {HTMLElementList} inputs
 */

export default class Filter {
    /**
     * @param {HTMLElement} element
     */
    constructor(element = null) {
        this.element = element;

        /** @type {String} */
        this.name = null;

        /** @type {domList} */
        this.dom = {
            info: null,
            display: null,
            selection: null,
        };

        /** @type {Boolean} */
        this.expandable = true;

        /** @type {Boolean} */
        this.additive = false; // Additive: More selected, more results. Redactive: More selected, less results

        this.selectedValues = [];
    }

    init() {
        if (this.element) {
            // Build easy access properties
            if (this.element.querySelectorAll('.info').length) {
                this.dom.info = this.element.querySelector('.info');
                this.dom.display = this.dom.info.querySelector('.name');
                this.dom.selection = this.dom.info.querySelector('.selection');
            }

            this.dom.inputs = this.element.querySelectorAll('ul li input');

            if (this.dom.inputs.length) {
                // Determine filter name
                this.name = this.dom.inputs[0].name;

                this.update(false);
                this.setEventListeners();
            } else {
                throw Error('No inputs in filter');
            }
        } else {
            throw Error('No element selected');
        }
    }

    setEventListeners() {
        this.dom.inputs.forEach(input => {
            input.addEventListener('change', this.update.bind(this));
        });

        if (this.expandable) {
            if (this.dom.info) {
                this.dom.info.addEventListener('click', e => {
                    e.stopPropagation();
                    this.toggle();
                 });
            }

            this.element.addEventListener('keydown', e => {
                switch (e.key.toLowerCase()) {
                    case ' ':
                    case 'enter':
                        this.toggle();
                        break;
                    case 'escape':
                    case 'esc':
                        this.hide();
                        break;
                }
            }, true);
        }
    }

    /**
     * @param {Boolean} dispatchEvent 
     */
    update(dispatchEvent = true) {
        const selectedValues = [];

        // Loop through the filter's inputs to get all selected values
        this.dom.inputs.forEach(input => {
            if (input.checked) {
                selectedValues.push(input.value);
            }
        });
        this.selectedValues = selectedValues;
        if (dispatchEvent) {
            this.element.dispatchEvent(new CustomEvent('update'));
        }

        if (this.dom.info) {
            this.dom.selection.innerHTML = this.formattedString(true);
        }
    }

    get selected() {
        return this.selectedValues;
    }

    /**
     * 
     * @param {HTMLElement} element 
     */
    decide(element) {
        let shouldShow;
        if (this.additive) {
            shouldShow = false;
        } else {
            shouldShow = true;
        }

        if (element.hasAttribute(`data-filter-${this.name}`)) {
            const elementProperties = element.getAttribute(`data-filter-${this.name}`).split(',');

            if (this.additive) {
                this.selectedValues.forEach(selectedValue => {
                    if (elementProperties.includes(selectedValue)) {
                        shouldShow = true;
                    }
                });
            } else {
                this.selectedValues.forEach(selectedValue => {
                    if (!elementProperties.includes(selectedValue)) {
                        shouldShow = false;
                    }
                });
            }

            return shouldShow;
        } else {
            // Element doesn't have any settings for this filter, should hide either way
            return false;
        }
    }

    /**
     * @param {Boolean} labels Return labels instead of values
     * @returns {String}
     */
    formattedString(labels = false) {
        if (labels) {
            const labels = [];

            // Build a list of checked items, get their labels
            this.dom.inputs.forEach(input => {
                if (input.checked) {
                    if (this.element.querySelector(`label[for="${input.id}"]`) !== undefined) {
                        labels.push(this.element.querySelector(`label[for="${input.id}"]`).innerHTML);
                    } else {
                        labels.push(input.value);
                    }
                }
            });

            return labels.join(', ');
        } else {
            return this.selected.join(', ');
        }
    }

    // Functions when filter's expandable

    toggle() {
        this.element.classList.toggle('expanded');
    }

    hide() {
        this.element.classList.remove('expanded');
    }

    show() {
        this.element.classList.add('expanded');
    }
}