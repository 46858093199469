import PagerElement from './pager';

export default class HomepagePagerElement extends PagerElement {
    constructor(properties = {}) {
        super(Object.assign(properties, {
            pagesArray: [1],
            previousUrl: '#',
            nextUrl: '#',
        }));

        this._template = require('./templates/homepagePager.handlebars');
    }

    setPagesArray(pagesArray = [1]) {
        this._property('pagesArray', pagesArray);

        if (this._object !== null) {
            this._object.querySelector('ul.pages').innerHTML = '';

            let pagesHtml = '';
            pagesArray.forEach(pageNumber => {
                pagesHtml += `<li class="${super.currentPage == pageNumber ? ' active' : ''}" data-page="${pageNumber}"><div class="page">
                        <span>${pageNumber}</span>
                    </div>
                </li>`;
            });

            this._object.querySelector('ul.pages').innerHTML = pagesHtml;
        }
    }

    set pagesArray(pagesArray = [1]) {
        this.setPagesArray(pagesArray);
    }

    get pagesArray() {
        return this._property('pagesArray');
    }

    set currentPage(currentPage = 1) {
        super.currentPage = currentPage;

        if (this._object !== null) {
            this._object.querySelectorAll('ul.pages li').forEach(page => {
                page.classList.remove('active');
            });

            if (this._object.querySelector(`ul.pages li[data-page="${currentPage}"]`)) {
                this._object.querySelector(`ul.pages li[data-page="${currentPage}"]`).classList.add('active');
            }
        }
    }

    set totalPages(totalPages = 1) {
        let updateArray = false;
        if (totalPages != super.totalPages) {
            updateArray = true;
        }

        super.totalPages = totalPages;
        
        if (updateArray) {
            let pagesArray = [];

            for (let i = 1; i <= totalPages; i++) {
                pagesArray.push(i);
            }

            this.pagesArray = pagesArray;
        }
    }
}