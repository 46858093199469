export default class HeaderController {
	constructor(autoinit = true) {
		this.previousScroll = 0;
		this.threshold = 100;
		this.headerElement = null;
		this.menuButton = null;
		this.lightHeader = false;

		if (autoinit) {
			this.init();
		}
	}

	init() {
		this.headerElement = document.querySelector('header');
		this.menuButton = this.headerElement.querySelector('.menuButton');
		this.previousScroll = window.pageYOffset;

		// Register events
		this.app.registerResizeEvent(this.resize.bind(this));
		this.app.registerScrollEvent(this.scroll.bind(this));

		// Click events
		this.menuButton.addEventListener('click', this.toggleMenu.bind(this));

		this.headerElement.querySelectorAll('.hasSubmenu').forEach(clickable => {
			clickable.addEventListener('click', e => {
				clickable.classList.toggle('submenuActive');
			});

			document.addEventListener('click', e => {
				if (!clickable.parentElement.contains(e.target) && window.innerWidth >= 768) {
					clickable.classList.remove('submenuActive');
				}
            });
		});
		
		// Prepare for animating the menu if the user hovers over the menubutton
		/* this.headerElement.querySelector('div.menuButton').addEventListener('mouseenter', () => {
			document.getElementById('overlay').classList.add('prepareAnimation');
		});
		this.headerElement.querySelector('div.menuButton').addEventListener('mouseleave', () => {
			document.getElementById('overlay').classList.remove('prepareAnimation');
		}); */
	}

	toggleMenu() {
		this.headerElement.classList.toggle('mobileMenuActive');
	}

	resize() {
		this.previousScroll = window.pageYOffset;
		this.threshold = this.headerElement.clientHeight;

		this.scroll();
	}

	scroll() {
		if (window.pageYOffset > this.threshold) {
			if (this.previousScroll < window.pageYOffset) {
				// Scroll down
				if (!this.mobileMenuActive) {
					this.hide();
				}
			} else {
				// Scroll up
				this.show();
			}
		} else {
			this.show();
		}

		this.previousScroll = window.pageYOffset;
	}

	hide() {
		this.headerElement.classList.add('hidden');

		this.headerElement.querySelectorAll('.submenuActive').forEach(el => el.classList.remove('submenuActive'));
	}

	show() {
		this.headerElement.classList.remove('hidden');
	}
}