export default class TooltipController {
    constructor(autoinit = true) {
        this.tooltip;
        this.selector = '[data-tooltip]';

        if (autoinit) {
            this.init();
        }
    }

    init() {
        if (document.querySelectorAll(this.selector).length) {
            import(/* webpackChunkName: "modules/tooltip" */ 'tippy.js').then(module => {
                this.tooltip = module.default;

                this.tooltips = this.tooltip(this.selector, {
                    content: el => el.dataset.tooltip,
                    theme: 'material',
                    maxWidth: 230,
                });
            });
        }
    }
}