export default class App {
	/**
	 * @param {boolean} [autoinit=true] Initialize on creating an instance
	 */
	constructor(autoinit = true) {
		this._resizeFunctions = [];
		this._resizeTimeout = null;
		this.resizeThrottle = false;

		this._scrollFunctions = [];
		this._scrollTimeout = null;
		this.scrollThrottle = false;

		if (autoinit) {
			this.init();
		}
	}
	
	init() {
		this.setEventListeners();

		this.resizeAnimationPreventer();
		this.detectBrowser();

		// Due to this having to be as exact as possible, ignore throttling
		const noteHeight = () => {
			const docEl = document.documentElement;
			docEl.style.setProperty('--fullHeight', `${window.innerHeight}px`);
		};
		window.addEventListener('resize', noteHeight);
		noteHeight();
	}

	setEventListeners() {
		// Adding passive as option to events helps with performance
		window.addEventListener('resize', this.resize.bind(this), {passive: true});
		document.addEventListener('scroll', this.scroll.bind(this), { passive: true });

		// Custom for inputs
		if (document.querySelector('form .inputRow')) {
            document.querySelectorAll('form .inputRow input').forEach(input => {
                const label = input.parentNode.querySelector('label');

                if (input.value.length) {
                    label.classList.add('entered');
				}
				
				input.addEventListener('input', e => {
					if (input.value.length) {
						label.classList.add('entered');
					} else {
						label.classList.remove('entered');
					}
				});

                input.addEventListener('focus', e => {
                    label.classList.add('active');
                });

                input.addEventListener('blur', e => {
                    label.classList.remove('active');
                });
            });
        }
	}

	/**
	 * @description Fires all registered events for a resize of the window
	 * */
	resize() {
		if (this._resizeTimeout !== null) {
			return;
		}

		const length = this._resizeFunctions.length;
		for (let i = 0; i < length; i++) {
			this._resizeFunctions[i]();
		}

		if (this.resizeThrottle) {
			this._resizeTimeout = setTimeout(() => {
				this._resizeTimeout = null;
			}, this.resizeThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a resize event
	 */
	registerResizeEvent(func) {
		this._resizeFunctions.push(func);
	}

	/**
	 * @param {function} func Function to be removed from the resize functions
	 */
	unregisterResizeEvent(func) {
		this._resizeFunctions.forEach((resizeFunc, index) => {
			if (resizeFunc === func) {
				this._resizeFunctions.split(index, 1);
			}
		});
	}

	/**
	 * @description Fires all registered events for a scroll event in the document
	 * */
	scroll() {
		if (this._scrollTimeout !== null) {
			return;
		}

		const length = this._scrollFunctions.length;
		for (let i = 0; i < length; i++) {
			this._scrollFunctions[i]();
		}

		if (this.scrollThrottle) {
			this._scrollTimeout = setTimeout(() => {
				this._scrollTimeout = null;
			}, this.scrollThrottle);
		}
	}

	/**
	 * @param {function} func Function to be called on a scroll event
	 */
	registerScrollEvent(func) {
		this._scrollFunctions.push(func);
	}

	/**
	 * @param {function} func Function to be removed from the scroll functions
	 */
	unregisterScrollEvent(func) {
		this._scrollFunctions.forEach((scrollFunc, index) => {
			if (scrollFunc === func) {
				this._scrollFunctions.split(index, 1);
			}
		});
	}

	/**
     * Registers a controller to the app and makes it cross-accessible
	 * @param {string} name Name the object will get in the app instance
	 * @param {object} object Object to be called
	 */
	addController(name, object) {
		this[name] = object;
		this[name].app = this;
	}

	resizeAnimationPreventer() {
		this.resizeTimer = null;
		this.windowWidth = window.innerWidth;
		this.registerResizeEvent(() => {
			if (this.windowWidth !== window.innerWidth) {
				this.windowWidth = window.innerWidth;
				document.body.classList.add('preventAnimations');
				clearTimeout(this.resizeTimer);

				this.resizeTimer = setTimeout(() => {
					document.body.classList.remove('preventAnimations');
				}, 400);
			}
		});	
	}

	/**
	 * @description Appends a browser-specific class to the html tag
	 */
	detectBrowser() {
		// Opera 8.0+
		let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

		// Firefox 1.0+
		let isFirefox = typeof InstallTrigger !== 'undefined';

		// Safari 3.0+ "[object HTMLElementConstructor]" 
		let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
			return p.toString() === "[object SafariRemoteNotification]";
		})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

		// Internet Explorer 6-11
		let isIE = /*@cc_on!@*/ false || !!document.documentMode;

		// Edge 20+
		let isEdge = !isIE && !!window.StyleMedia;

		// Chrome 1 - 71
		let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

		// Blink engine detection
		let isBlink = (isChrome || isOpera) && !!window.CSS;

		if (isOpera) {
			document.documentElement.classList.add('opera');
		}

		if (isFirefox) {
			document.documentElement.classList.add('firefox');
		}

		if (isSafari) {
			document.documentElement.classList.add('safari');
		}

		if (isIE) {
			document.documentElement.classList.add('ie');
		}

		if (isEdge) {
			document.documentElement.classList.add('edge');
		}

		if (isChrome) {
			document.documentElement.classList.add('chrome');
		}

		if (isBlink) {
			document.documentElement.classList.add('blink');
		}
	}
}