var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                <li class=\"\" data-page=\""
    + alias2(alias1(depth0, depth0))
    + "\">\r\n                    <div class=\"page\">\r\n                        <span>"
    + alias2(alias1(depth0, depth0))
    + "</span>\r\n                    </div>\r\n                </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pager\">\r\n    <div class=\"controlBlock\">\r\n        <div class=\"controls\">\r\n            <a class=\"previous\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"previousUrl") : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelPreviousTitle") : depth0), depth0))
    + "\">\r\n                <svg class=\"sprite icon_chevron_left\">\r\n                    <use xlink:href=\"#icon_chevron_left\" />\r\n                </svg>\r\n            </a>\r\n            <ul class=\"pages\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"pagesArray") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n            <a class=\"next\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nextUrl") : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelNextTitle") : depth0), depth0))
    + "\">\r\n                <svg class=\"sprite icon_chevron_left right\">\r\n                    <use xlink:href=\"#icon_chevron_left\" />\r\n                </svg>\r\n            </a>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true});