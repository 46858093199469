import Tabs from './tabs.js';

export default class TabsController {
    constructor() {
        this.selector = '.tabs';
        this.tabs = [];

        this.init();
    }

    init() {
        if (document.querySelectorAll(this.selector).length) {
            document.querySelectorAll(this.selector).forEach(tabsElement => {
                let tabs = new Tabs(tabsElement);
                this.tabs.push(tabs);

                tabs.init();
            });
        }
    }
}