/**
 * Class representing an HTML element
 */
export default class CustomHTMLElement {
    /**
     * Creates the CustomHTMLElement
     * @param {object} defaultProperties - List of default element properties
     */
	constructor(defaultProperties = {}) {
		if (window.HTMLID === undefined) {
			window.HTMLID = 0;
		}
		window.HTMLID++;
		this._id = '_' + window.HTMLID;
		this._template = null;
		this._html = null;
		this._object = null;

		this._properties = defaultProperties;
	}

    /**
     * Generates a DOM object
     * @param {boolean} [forceRenew=false] - Forces a new HTMLElement to be created
     * @returns {HTMLElement}
     */
	generate(forceRenew = false) {
		if (this._template === null) {
			throw new Error('No template was set');
		}

		if (this._object !== null && !forceRenew) {
			return this._object;
		} else {
			let containerElement = document.createElement('div');
			this._html = this._template(this._properties);
			containerElement.innerHTML = this._html;
			this._object = containerElement.firstChild;

			return this._object;
		}
	}

    /**
     * Set properties for the element
     * @param {object} properties - List of properties that can be set on creating a new element
     */
	setProperties(properties, log) {
		if (Object.keys(properties).length > 0) {
			Object.keys(properties).forEach(key => {
				if ((typeof this[`set${key.charAt(0).toUpperCase()}${key.substring(1)}`]) === 'function') {
					this[`set${key.charAt(0).toUpperCase()}${key.substring(1)}`](properties[key]);
				} else {
					this._properties[key] = properties[key];
				}
			});
		}
	}

    /**
     * Sets and returns one of the element's properties
     * @param {string} key - The property's name
     * @param {*} value - The property's value
     * @returns {*}
     */
	_property(key, value) {
		if (value === undefined) {
			return this._properties[key];
		} else {
			this._properties[key] = value;
			return this._properties[key];
		}
	}
}