export default class ParallaxController {
    constructor(autoinit = true) {
        if (autoinit) {
            this.init();

            this.mouseScenes = [];
            this.scrollScenes = [];
        }
    }

    init() {
        if (document.querySelectorAll('.parallaxSceneMouse').length) {
            this.runMouseParallax();
        }

        if (document.querySelectorAll('.parallaxSceneScroll').length) {
            this.runScrollParallax();
        }
    }

    runMouseParallax() {
        import(/* webpackChunkName: "modules/parallaxMouse" */ 'parallax-js').then(module => {
            const Parallax = module.default;

            document.querySelectorAll('.parallaxSceneMouse').forEach(scene => {

                if (!(document.documentElement.classList.contains('firefox') && scene.classList.contains('noFF')) && !document.documentElement.classList.contains('ie')) {
                    this.mouseScenes.push(new Parallax(scene));
                }
            });
        });
    }

    runScrollParallax() {
        import(/* webpackChunkName: "modules/parallaxScroll" */ 'rellax').then(module => {
            const Parallax = module.default;

            document.querySelectorAll('.parallaxSceneScroll').forEach(scene => {
                this.scrollScenes.push(new Parallax(scene, {
                    center: true
                }));
            });
        });
    }
}