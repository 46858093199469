import Pager from './../../elements/pager';

export default class PagedContainer {
    /**
     * 
     * @param {HTMLElement} container 
     * @param {Object} settings
     * @param {String} settings.pagedClass
     * @param {Number} settings.itemsPerPage
     * @param {?String} settings.childrenSelector
     * @param {Boolean} settings.pager
     * @param {HTMLElement} settings.pagerContainer
     * @param {Boolean} settings.scrollOnNewPage
     * @param {Number} settings.scrollOffset
     * @param {?String} settings.classToSkip Classes that don't get counted towards filters
     */
    constructor(container, settings = {}) {
        this.container = container;
        this.items = [];
        this._currentPage = 1;
        this._totalPages = 1;
        this.pager = null;

        // Settings
        this.settings = {
            pagedClass: 'paged',
            itemsPerPage: 4,
            childrenSelector: null,
            pager: true,
            pagerContainer: null,
            scrollOnNewPage: true,
            scrollOffset: 150,
            classToSkip: null
        }

        this.loadSettings(settings);
    }

    set currentPage(currentPage = 1) {
        this._currentPage = currentPage;

        if (this.settings.pager && this.pager) {
            this.pager.currentPage = currentPage;
        }
    }

    get currentPage() {
        return parseInt(this._currentPage);
    }

    set totalPages(totalPages = 1) {
        this._totalPages = totalPages;

        if (this.settings.pager && this.pager) {
            this.pager.totalPages = totalPages;
        }
    }

    get totalPages() {
        return parseInt(this._totalPages);
    }
    
    init() {

        if (this.settings.childrenSelector == null) {
            [...this.container.children].forEach(item => this.items.push(item));
        } else {
            this.container.querySelectorAll(this.settings.childrenSelector).forEach(item => this.items.push(item));
        }

        if (this.settings.pager) {
            this.initPager();
        }

        this.showPage(this.currentPage, true);
        this.setEventListeners();
    }

    initPager() {
        if (this.pager == null) {
            this.pager = new Pager({
                currentPage: 1,
                totalPages: 1
            });
        }

        if (this.settings.pagerContainer !== null) {
            this.settings.pagerContainer.appendChild(this.pager.generate());
        } else {
            console.info(`Pager created but not inserted yet`);
            console.log(this.pager);
        }
    }

    setEventListeners() {
        if (this.settings.pager && this.pager) {
            this.pager.generate().querySelectorAll('.previous').forEach(previous => previous.addEventListener('click', e => {
                e.preventDefault();
                this.showPrevious();
            }));
            this.pager.generate().querySelectorAll('.next').forEach(next => next.addEventListener('click', e => {
                e.preventDefault();
                this.showNext();
            }));
        }
    }

    /**
     * 
     * @param {Number} pageNumber 
     */
    showPage(pageNumber, instant = false) {
        const start = () => {
            this.calculateTotalPages();
            if (pageNumber < 1) {
                pageNumber = 1;
            } else if (pageNumber > this.totalPages) {
                pageNumber = this.totalPages;
            }

            let items = this.items;
            if (this.settings.classToSkip) {
                items = items.filter(item => !item.classList.contains(this.settings.classToSkip));
            }

            let firstIndex = (pageNumber - 1) * this.settings.itemsPerPage;
            let lastIndex = Math.min((pageNumber * this.settings.itemsPerPage) - 1, items.length - 1);
            let itemsToShow = items.filter((item, index) => index >= firstIndex && index <= lastIndex);
            this.hideAll();
            this.showItems(itemsToShow);

            this.currentPage = pageNumber;

            if (this.settings.pager && this.pager) {
                if (this.currentPage == 1) {
                    this.pager.generate().querySelectorAll('.previous').forEach(previous => previous.setAttribute('disabled', 'disabled'));
                } else {
                    this.pager.generate().querySelectorAll('.previous').forEach(previous => previous.removeAttribute('disabled'));
                }

                if (this.currentPage >= this.totalPages) {
                    this.pager.generate().querySelectorAll('.next').forEach(next => next.setAttribute('disabled', 'disabled'));
                } else {
                    this.pager.generate().querySelectorAll('.next').forEach(next => next.removeAttribute('disabled'));
                }
            }

            if (window.slimmage) {
                window.slimmage.cr();
            }

            this.dispatchUpdateEvent();
        };

        if (this.settings.scrollOnNewPage && !instant) {
            this.scrollToTop().then(() => start());
        } else {
            start();
        }
    }

    scrollToTop() {
        if (typeof scrollIntoView == 'undefined') {
            return Promise.resolve();
        } else {
            return new Promise(resolve => {
                window.scrollTo({
                    top: this.container.getBoundingClientRect().top + window.scrollY + this.settings.scrollOffset,
                    behavior: 'smooth',
                });
                setTimeout(resolve, 500);
            });
        }
    }

    showNext() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.showPage(this.currentPage);
        }
    }

    showPrevious() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.showPage(this.currentPage);
        }
    }

    hideAll() {
        this.items.forEach(item => {
            item.classList.add(this.settings.pagedClass);
        });
    }

    showItems(items) {
        items.forEach(item => {
            item.classList.remove(this.settings.pagedClass);
        });
    }

    calculateTotalPages() {
        let items = this.items;
        if (this.settings.classToSkip) {
            items = items.filter(item => !item.classList.contains(this.settings.classToSkip));
        }

        this.totalPages = Math.ceil(items.length / this.settings.itemsPerPage);
    }

    dispatchUpdateEvent() {
        const e = new CustomEvent('update', {
            detail: {
                currentPage: this.currentPage,
                totalPages: this.totalPages
            }
        });

        this.container.dispatchEvent(e);
    }

    loadSettings(settings) {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }

        // Check if any of the settings match a dataset first
        Object.keys(this.settings).forEach(setting => {
            if (this.container.dataset[`paged${capitalizeFirstLetter(setting)}`]) {
                if (typeof this.settings[setting] == 'number') {
                    this.settings[setting] = parseInt(this.container.dataset[`paged${capitalizeFirstLetter(setting)}`]);
                } else {
                    this.settings[setting] = this.container.dataset[`paged${capitalizeFirstLetter(setting)}`];
                }
            }
        });

        if (Object.keys(settings).length) {
            Object.keys(settings).forEach(setting => {
                if (this.settings[setting] !== undefined) {
                    this.settings[setting] = settings[setting];
                }
            });
        }
    }
}