import Filter from './filters/filter';
import FilterGroup from './filters/filterGroup';
import HomepagePager from '../elements/homepagePager';
import PagedContainer from './paging/pagedContainer';

export default class HomepageController {
    constructor() {
        this.productFilter;
        this.productFilterGroup;
        this.currentPage = 1;
        this.totalPages = 1;
        this.itemsPerPage = 4;


        if (document.body.classList.contains('homepage')) {
            this.init();
        }
    }

    init() {
        if (document.querySelector('.highlightedProducts .filterInput') != null) {
            this.productFilter = new Filter(document.querySelector('.highlightedProducts .filterInput'));
            this.productFilter.init();

            this.productFilterGroup = new FilterGroup([this.productFilter], document.querySelectorAll('.highlightedProductsList > li'), {});
            this.productFilterGroup.init();

            this.pagedContainer = new PagedContainer(document.querySelector('.highlightedProductsList'), {
                itemsPerPage: this.itemsPerPage,
                pagerContainer: document.querySelector('.highlightedProductsList + .pagerContainer'),
                scrollOnNewPage: false,
                classToSkip: 'filtered',
            });
            this.pagedContainer.pager = new HomepagePager();
            this.pagedContainer.init();
            
            this.setEventListeners();
        }
    }

    setEventListeners() {
        import( /* webpackChunkName: "modules/delegate" */ 'delegate').then(module => {
            this.delegate = module.default;

            this.delegate('.highlightedProductsList + .pagerContainer .pages li', 'click', e => {
                if (e.delegateTarget && e.delegateTarget.dataset.page) {
                    this.pagedContainer.showPage(e.delegateTarget.dataset.page);
                }
            });
        });

        document.querySelector('.highlightedProducts .filterInput').addEventListener('update', e => {
            this.pagedContainer.showPage(1);
        });
    }
}