import CustomHTMLElement from './customHtmlElement';

export default class Journal extends CustomHTMLElement {
	constructor(properties = {}) {
		super({
			title: '',
			author: '',
			likes: 0,
			url: '',
			tags: [],
			date: '',
			image: '',
			isVideo: false,
			labelATitle: 'Ga naar de pagina '
		});
		this.setProperties(properties);

		this._template = require('./templates/journal.handlebars');
	}

	setTitle(title = '') {
		this._property('title', `${title}`);
	}

	set title(title = '') {
		this.setTitle(title);
	}

	get title() {
		return this._property('title');
	}

	setAuthor(author = '') {
		this._property('author', `${author}`);
	}

	set author(author = '') {
		this.setAuthor(author);
	}

	get author() {
		return this._property('author');
	}

	setLikes(likes = 0) {
		this._property('likes', parseInt(likes));
	}

	set likes(likes = 0) {
		this.setLikes(likes);
	}

	get likes() {
		return this._property('likes');
	}

	setUrl(url = '') {
		this._property('url', `${url}`);
	}

	set url(url = '') {
		this.setUrl(url);
	}

	get url() {
		return this._property('url');
	}

	setTags(tags = []) {
		if (typeof tags !== 'object') {
			tags = [tags];
		}

		this._property('tags', tags);
	}

	set tags(tags = []) {
		this.setTags(tags);
	}

	get tags() {
		return this._property('tags');
	}

	setDate(date = '') {
		this._property('date', `${date}`);
	}

	set date(date = '') {
		this.setDate(date);
	}

	get date() {
		return this._property('date');
	}

	setImage(image = '') {
		this._property('image', `${image}`);
	}

	set image(image = '') {
		this.setImage(image);
	}

	get image() {
		return this._property('image');
	}

	setIsVideo(isVideo = false) {
		this._property('isVideo', !!isVideo);
	}

	set isVideo(isVideo = false) {
		this.setIsVideo(isVideo);
	}

	get isVideo() {
		return this._property('isVideo');
	}
	
	setLabelATitle(label = 'Ga naar de pagina ') {
		this._property('labelATitle', `${label}`);
	}

	set labelATitle(label = 'Ga naar de pagina ') {
		this.setLabelATitle(label);
	}

	get labelATitle() {
		return this._property('labelATitle');
	}
}