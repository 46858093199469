import './libraries/slimmageSettings';
import './libraries/slimmage';

// App
import App from './app';
import PolyfillController from './controllers/polyfill';

// Controllers
import HeaderController from './controllers/header';
import MediaController from './controllers/media';
import AVGController from './controllers/avg';
import TabsController from './controllers/tabs/controller';
import ToastController from './controllers/toast/controller';
// import SearchController from './controllers/search';
import AccordeonController from './controllers/accordeon/controller';
import LandingController from './controllers/landing';
import ParallaxController from './controllers/parallax';
import ShareController from './controllers/share';
import LikeController from './controllers/like';
import TooltipController from './controllers/tooltip';
import JournalOverviewController from './controllers/journalsOverview';
import ProjectsOverviewController from './controllers/projectsOverview';
import CoursesOverviewController from './controllers/coursesOverview';
import StribersOverviewController from './controllers/stribersOverview';
import SliderController from './controllers/slider';
import HomepageController from './controllers/homepage';
import LoginController from './controllers/login';
import PartlyHiddenController from './controllers/partlyHiddenController';

(() => {
    document.querySelector('html').classList.remove('no-js');

    const polyfills = new PolyfillController();

    polyfills.init().then(() => {
        const app = new App();
        window.app = app;

        app.resizeThrottle = 10;
        app.scrollThrottle = 100;

        app.addController('polyfillController', polyfills);

        app.addController('AVGController', new AVGController());

        if (!document.body.classList.contains('landingpage')) {
            app.addController('headerController', new HeaderController(false));
            app.headerController.init();
        }

        app.addController('mediaController', new MediaController(false));
        app.mediaController.init();

        app.addController('tabsController', new TabsController());
        app.addController('toastController', new ToastController());
        
        app.addController('shareController', new ShareController(false));
        app.shareController.tagTitle = 'Deel dit item via MEDIA';
        if (document.body.parentNode.hasAttribute('lang') && document.body.parentNode.getAttribute('lang').toLowerCase() == 'en') {
            app.shareController.medias.email.name = 'email';
            app.shareController.tagTitle = 'Share this item using MEDIA';
        }

        app.shareController.init();

        app.addController('likeController', new LikeController());

        if (document.body.classList.contains('landingpage')) {
            app.addController('landingController', new LandingController());
        }

        // app.addController('searchController', new SearchController());
        app.addController('accordeonController', new AccordeonController());
        app.addController('parallaxController', new ParallaxController());
        app.addController('tooltipController', new TooltipController());
        app.addController('journalsOverviewController', new JournalOverviewController());
        app.addController('projectsOverviewController', new ProjectsOverviewController());
        app.addController('coursesOverviewController', new CoursesOverviewController());
        app.addController('stribersOvervieController', new StribersOverviewController());
        app.addController('sliderController', new SliderController());
        app.addController('homepageController', new HomepageController());
        app.addController('partlyHiddenController', new PartlyHiddenController());

        if (document.body.getAttribute('class').indexOf('mystribe') > -1) {
            import( /* webpackChunkName: "modules/myStribe" */ './controllers/myStribe').then(module => {
                app.addController('myStribeController', new module.default());
            });
        }

        if (document.body.getAttribute('class').indexOf('shop') > -1) {
            // import ShopController from './controllers/shop/controller';
            import( /* webpackChunkName: "modules/shop" */ './controllers/shop/controller').then(module => {
                app.addController('shopController', new module.default());
            });
        }

        app.addController('loginController', new LoginController());

        console.log(`App initialized`);
        document.body.classList.remove('preventAnimations');
    });
})();