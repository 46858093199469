import Filter from './filters/filter';
import FilterGroup from './filters/filterGroup';
import Journal from '../elements/journal';
import Pager from '../elements/pager';
import {createBrowserHistory} from 'history';

export default class StribersOverviewController {
    constructor() {
        this.filterGroup;
        this.dom;
        this.currentPage = 1;
        this.totalPages = 1;
        this.itemsPerPage = 15;
        this.sort = 'recent';
        this.api = '/umbraco/api/stribersoverview/filter';
        this.apiCall = null;
        this.apiCallAbortController = new AbortController();
        this.history = createBrowserHistory();
        this.historyListener = null;

        this.labels = {
            pagingNext: 'Volgende',
            pagingOf: 'van',
            pagingPreviousTitle: 'Ga naar de vorige pagina',
            pagingNextTitle: 'Ga naar de volgende pagina',
            journalATitle: 'Ga naar de pagina ',
            showXFilters: '+ {0} meer',
            noResults: 'geen resultaten gevonden. al geprobeerd je filters aan te passen?',
        };
        
        if (document.body.classList.contains('stribersoverviewpage')) {
            this.init();
        }
    }

    init() {
        this.dom = {
            filtersContainer: document.querySelector('.filterContainer'),
            targetsContainer: document.querySelector('.targetsContainer'),
            pagerContainer: document.querySelector('.pagerContainer'),
        };

        const filters = [];
        this.dom.filtersContainer.querySelectorAll('.filterInput').forEach(filterInput => {
            let filter = new Filter(filterInput);
            filter.expandable = false;
            filter.additive = true;
            filter.init();
            filters.push(filter);
        });

        this.filterGroup = new FilterGroup(filters, this.dom.targetsContainer.children, {
            api: true
        });
        this.filterGroup.init();

        // Set defaults
        let el = document.getElementById('filterData');
        this.currentPage = parseInt(el.dataset.currentPage);
        this.totalPages = parseInt(el.dataset.totalPages);
        this.itemsPerPage = parseInt(el.dataset.itemsPerPage);

        // Labels
        if (el.dataset.pagingLabelNext) {
            this.labels.pagingNext = el.dataset.pagingLabelNext;
        }

        if (el.dataset.pagingLabelOf) {
            this.labels.pagingOf = el.dataset.pagingLabelOf;
        }

        if (el.dataset.pagingLabelOf) {
            this.labels.pagingOf = el.dataset.pagingLabelOf;
        }

        if (el.dataset.pagingLabelPreviousTitle) {
            this.labels.pagingPreviousTitle = el.dataset.pagingLabelPreviousTitle;
        }

        if (el.dataset.pagingLabelNextTitle) {
            this.labels.pagingNextTitle = el.dataset.pagingLabelNextTitle;
        }

        if (el.dataset.journalATitle) {
            this.labels.journalATitle = el.dataset.journalATitle;
        }

        if (el.dataset.showXFilters) {
            this.labels.showXFilters = el.dataset.showXFilters;
        }

        if (el.dataset.noResults) {
            this.labels.noResults = el.dataset.noResults;
        }

        if(el.dataset.lang){
            this.lang = el.dataset.lang;
        }

        this.pager = new Pager({
            currentPage: this.currentPage,
            totalPages: this.totalPages,

            labelNext: this.labels.pagingNext,
            labelOf: this.labels.pagingOf,
            labelPreviousTitle: this.labels.pagingPreviousTitle,
            labelNextTitle: this.labels.pagingNextTitle,
        });

        if (this.currentPage > 1) {
            this.pager.previousUrl = `${location.pathname}?page=${this.currentPage - 1}`;
        }

        if (this.currentPage < this.totalPages) {
            this.pager.nextUrl = `${location.pathname}?page=${this.currentPage + 1}`;
        }

        // Replace pager from backend with JS-controlled one
        this.dom.pagerContainer.innerHTML = '';
        this.dom.pagerContainer.appendChild(this.pager.generate());
        this.pager.currentPage = this.currentPage; // Forces a recheck to enable/disable buttons in the pager

        this.hideXFilters();

        this.setEventListeners();
    }

    setEventListeners() {
        this.filterGroup.filters.forEach(filter => {
            filter.element.addEventListener('update', () => {
                this.currentPage = 1; // Reset paging because filters completely change the shown items in the results
                this.update();
            });
        });

        // Sort
        document.querySelectorAll('.sortOption input').forEach(sortInput => {
            sortInput.addEventListener('change', () => {
                this.sort = document.querySelector('.sortOption input:checked').value;
                this.currentPage = 1;
                this.update();

                /* switch (this.sort) {
                    case 'popularity':
                        document.querySelector('h1').innerHTML = this.labels.titlePopular;
                    break;

                    case 'date':
                    default:
                        document.querySelector('h1').innerHTML = this.labels.titleDate;
                    break;
                } */
            });
        });

        this.pager.generate().querySelectorAll('a.next').forEach(a => {
            a.addEventListener('click', e => {
                e.preventDefault();

                this.currentPage++;
                this.update();
            });
        });

        this.pager.generate().querySelectorAll('a.previous').forEach(a => {
            a.addEventListener('click', e => {
                e.preventDefault();

                this.currentPage--;
                this.update();
            });
        });

        // Listeners for filter visibility
        // Mobile only: open filter popup
        document.querySelector('a.openFilters').addEventListener('click', e => {
            e.preventDefault();
            this.dom.filtersContainer.classList.remove('mobileHidden');
        });

        // Mobile only: close filter popup
        this.dom.filtersContainer.querySelector('.filterContainerClose').addEventListener('click', () => {
            this.dom.filtersContainer.classList.add('mobileHidden');
        });
        /* this.dom.filterContainer.querySelector('a.c2a').addEventListener('click', () => {
            this.filterContainer.classList.add('hidden');
        }); */

        // Mobile only: close filter popup on escape
        document.addEventListener('keyup', e => {
            if (e.keyCode === 27/*  && (this.app.isXS() || this.app.isSM()) */) {
                this.dom.filtersContainer.classList.add('mobileHidden');
            }
        });

        this.filterGroup.filters.forEach(filter => {
            filter.dom.display.addEventListener('click', () => {
                filter.element.classList.toggle('expanded');
            });
        });

        // History
        this.historyListener = this.history.listen((undefined, action) => {
            if (action == 'POP') {
                if (this.getURLParameters().page) {
                    this.currentPage = parseInt(this.getURLParameters().page);
                    this.update(false);
                }
            }
        });
    }

    createList(data) {
        if (data.length) {
            data.forEach(item => {
                let striber = new Journal({ // Reusing journal template
                    title: item.Title,
                    author: item.AuthorName,
                    // likes: item.Likes,
                    url: item.Url,
                    tags: item.tags,
                    // date: item.CreateDate,
                    image: item.ImageUrl,
                    isVideo: item.IsVideo,
                    labelATitle: this.labels.journalATitle
                });

                this.dom.targetsContainer.appendChild(striber.generate());
            });

            window.slimmage.cr();
        } else {
            const message = document.createElement('span');
            message.setAttribute('style', `opacity: .9;padding: 20px 15px;`);
            message.innerText = this.labels.noResults || 'geen resultaten';
            this.dom.targetsContainer.appendChild(message);
        }
    }

    updatePager() {
        this.pager.totalPages = this.totalPages;
        this.pager.currentPage = this.currentPage;

        if (this.filterGroup.filterData.striberid) {
            if (this.currentPage > 1) {
                this.pager.previousUrl = `${location.pathname}?striber=${this.filterGroup.filterData.striberid}&page=${this.currentPage - 1}`;
            }
    
            if (this.currentPage < this.totalPages) {
                this.pager.nextUrl = `${location.pathname}?striber=${this.filterGroup.filterData.striberid}&page=${this.currentPage + 1}`;
            }
        } else {
            if (this.currentPage > 1) {
                this.pager.previousUrl = `${location.pathname}?page=${this.currentPage - 1}`;
            }
    
            if (this.currentPage < this.totalPages) {
                this.pager.nextUrl = `${location.pathname}?page=${this.currentPage + 1}`;
            }
        }
    }

    updateUrl() {
        let pathname = ``;
        if (this.filterGroup.filterData.striberid) {
            if (this.currentPage == 1) {
                pathname = `?striberid=${this.filterGroup.filterData.striberid}`;
            } else {
                pathname = `?striberid=${this.filterGroup.filterData.striberid}&page=${this.currentPage}`;
            }
        } else {
            if (!this.currentPage == 1) {
                pathname = `?page=${this.currentPage}`;
            }
        }

        this.history.push({pathname});
        
    }

    clearList() {
        this.dom.targetsContainer.innerHTML = '';
    }

    update(updateHistory = true) {
        let data = this.filterGroup.filterData;
        data.page = this.currentPage;
        data.itemsPerPage = this.itemsPerPage;
        data.sortOrder = this.sort;
        data.lang = this.lang;

        let queryString = '?' + Object.keys(data).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        }).join('&');

        // If another call is in progress, cancel it.
        if (this.apiCall !== null) {
            this.apiCallAbortController.abort();
            this.apiCall = null;
        }

        this.apiCall = fetch(`${this.api}${queryString}`, {
            signal: this.apiCallAbortController.signal,
        });
        this.apiCall.then(response => {
            return response.json();
        }).then(result => {
            this.currentPage = result.CurrentPage;
            this.totalPages = result.TotalPages;

            this.clearList();
            this.createList(result.Data);
            this.updatePager();

            if (updateHistory) {
                this.updateUrl();
            }

            this.apiCall = null;
        }).catch(err => {
            console.log('Call cancelled');
        });
    }

    getURLParameters() {
        let vars = {};
        let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    hideXFilters() {
        this.filterGroup.filters.forEach(filter => {
            if (filter.element.dataset.show !== undefined) {
                let show = parseInt(filter.element.dataset.show);

                let hidden = [];
                filter.element.querySelectorAll('ul li').forEach((listitem, index) => {
                    if ((index + 1) > show) {
                        // Only hide if it isn't checked
                        if (!listitem.querySelector('input').checked) {
                            hidden.push(listitem);
                        }
                    }
                });

                if (hidden.length > 1) {
                    // Add a showAll link after the list
                    let tempDiv = document.createElement('div');
                    tempDiv.innerHTML = `<a href="javascript:void(0);" class="showAll" title="${this.labels.showXFilters.replace('{0}', hidden.length)}">${this.labels.showXFilters.replace('{0}', hidden.length)}</a>`;
                    const a = tempDiv.firstChild;
                    a.addEventListener('click', e => {
                        hidden.forEach(listitem => listitem.classList.remove('hidden'));
                        a.remove();
                    });

                    hidden.forEach(listitem => listitem.classList.add('hidden'));

                    filter.element.querySelector('ul').parentNode.insertBefore(a, filter.element.querySelector('ul').nextSibling);
                }
            }
        });
    }
}