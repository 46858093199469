import CustomHTMLElement from './customHtmlElement';

export default class PagerElement extends CustomHTMLElement {
	constructor(properties = {}) {
		super({
            currentPage: 1,
            totalPages: 1,
            previousUrl: '',
            nextUrl: '',
            labelNext: 'Volgende',
            labelOf: 'van',
            labelPreviousTitle: 'Ga naar de vorige pagina',
            labelNextTitle: 'Ga naar de volgende pagina',
		});
		this.setProperties(properties);

		this._template = require('./templates/pager.handlebars');
	}
    
    setCurrentPage(currentPage = 1) {
        this._property('currentPage', parseInt(currentPage));

        if (this._object !== null) {
            if (this._object.querySelector('.current span')) {
                this._object.querySelector('.current span').innerHTML = this._property('currentPage');
            }

            // Check if buttons need to be updated
            if (this.currentPage >= this.totalPages) {
                // Disable next buttons
                this._object.querySelectorAll('.next').forEach(next => {
                    next.setAttribute('disabled', 'disabled');
                    next.blur();
                });
            } else {
                // Enable next buttons
                this._object.querySelectorAll('.next').forEach(next => {
                    next.removeAttribute('disabled');
                });
            }

            if (this.currentPage <= 1) {
                // Disable previous buttons
                this._object.querySelectorAll('.previous').forEach(previous => {
                    previous.setAttribute('disabled', 'disabled');
                    previous.blur();
                });
            } else {
                // Enable previous buttons
                this._object.querySelectorAll('.previous').forEach(previous => {
                    previous.removeAttribute('disabled');
                });
            }
        }
    }

    set currentPage(currentPage = 1) {
        this.setCurrentPage(currentPage);
    }

    get currentPage() {
        return this._property('currentPage');
    }

    setTotalPages(totalPages = 1) {
        this._property('totalPages', parseInt(totalPages));

        if (this._object !== null && this._object.querySelector('.total span')) {
            this._object.querySelector('.total span').innerHTML = this._property('totalPages');
        }
    }

    set totalPages(totalPages = 1) {
        this.setTotalPages(totalPages);
    }

    get totalPages() {
        return this._property('totalPages');
    }

    setPreviousUrl(url = '') {
        this._property('previousUrl', `${url}`);

        if (this._object !== null) {
            this._object.querySelectorAll('a.previous').forEach(a => a.href = this.previousUrl);
        }
    }

    set previousUrl(url = '') {
        this.setPreviousUrl(url);
    }

    get previousUrl() {
        return this._property('previousUrl');
    }

    setNextUrl(url = '') {
        this._property('nextUrl', `${url}`);

        if (this._object !== null) {
            this._object.querySelectorAll('a.next').forEach(a => a.href = this.nextUrl);
        }
    }

    set nextUrl(url = '') {
        this.setNextUrl(url);
    }

    get nextUrl() {
        return this._property('nextUrl');
    }

    setLabelNext(label = 'Volgende') {
        this._property('labelNext', `${label}`);
    }

    set labelNext(label = 'Volgende') {
        this.setLabelNext(label);
    }

    get labelNext() {
        return this._property('labelNext');
    }

    setLabelOf(label = 'van') {
        this._property('labelOf', `${label}`);
    }

    set labelNext(label = 'van') {
        this.setLabelOf(label);
    }

    get labelOf() {
        return this._property('labelOf');
    }

    setLabelPreviousTitle(label = 'Ga naar de vorige pagina') {
        this._property('labelPreviousTitle', `${label}`);
    }

    set labelPreviousTitle(label = 'Ga naar de vorige pagina') {
        this.setLabelPreviousTitle(label);
    }

    get labelPreviousTitle() {
        return this._property('labelPreviousTitle');
    }

    setLabelNextTitle(label = 'Ga naar de volgende pagina') {
        this._property('labelNextTitle', `${label}`);
    }

    set labelNextTitle(label = 'Ga naar de volgende pagina') {
        this.setLabelNextTitle(label);
    }

    get labelNextTitle() {
        return this._property('labelNextTitle');
    }
}