var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pager\">\r\n    <a class=\"next button clear\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nextUrl") : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelNextTitle") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelNext") : depth0), depth0))
    + "</a>\r\n\r\n    <div class=\"controlBlock\">\r\n        <div class=\"controls\">\r\n            <a class=\"previous\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"previousUrl") : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelPreviousTitle") : depth0), depth0))
    + "\">\r\n                <svg class=\"sprite icon_chevron_left\">\r\n                    <use xlink:href=\"#icon_chevron_left\" />\r\n                </svg>\r\n            </a>\r\n            <div class=\"current\">\r\n                <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"currentPage") : depth0), depth0))
    + "</span>\r\n            </div>\r\n            <a class=\"next\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nextUrl") : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelNextTitle") : depth0), depth0))
    + "\">\r\n                <svg class=\"sprite icon_chevron_left right\">\r\n                    <use xlink:href=\"#icon_chevron_left\" />\r\n                </svg>\r\n            </a>\r\n        </div>\r\n        <div class=\"total\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"labelOf") : depth0), depth0))
    + " <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"totalPages") : depth0), depth0))
    + "</span></div>\r\n    </div>\r\n</div>";
},"useData":true});