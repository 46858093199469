import Cookies from 'js-cookie';

export default class MediaController {
	constructor(autoinit = true) {
		if (autoinit) {
			this.init();
		}
	}

	init() {
		var youtube = false;
		var vimeo = false;
		document.querySelectorAll('noscript').forEach(media => {
			switch (media.dataset.mediatype) {
				case 'youtube':
					this.youtube(media);
					youtube = true;
					break;
				case 'vimeo':
					this.vimeo(media);
					vimeo = true;
					break;
			}
		});

		if (this.app.AVGController.AcceptedTrackingCookies) {
			if (youtube) {
				var tag = document.createElement('script');
				tag.src = "//www.youtube.com/player_api";
				var firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

				window.onYouTubeIframeAPIReady = this.YouTubeAPILoaded.bind(this);
			}

			if (vimeo) {
				import( /* webpackChunkName: "modules/vimeo" */ '@vimeo/player').then(module => {
					const Player = module.default;
					document.querySelectorAll('iframe.vimeo').forEach(iframe => {
						var player = new Player(iframe);

						iframe.parentNode.querySelector('div.videoPoster a').addEventListener('click', (click) => {
							let videoPoster = click.currentTarget.closest('div.videoPoster');
							videoPoster.remove();
							player.play().then(function() {
							}).catch(function(error) {
								console.error(error);
							});
						}, true);
					});
				});
			}
		}
	}

	YouTubeAPILoaded() {
		document.querySelectorAll('iframe.youtube').forEach(iframe => {
			var player = new YT.Player(iframe.id, {
				events: {
					'onReady': event => {
						this.YouTubeIFrameLoaded(event, iframe);
					}
				}
			});
		})
	}

	YouTubeIFrameLoaded(event, target) {
		target.parentNode.querySelector('div.videoPoster a').addEventListener('click', (click) => {
			click.currentTarget.closest('div.videoPoster').remove();
			event.target.playVideo();
		}, true);
	}


	embedIframe(url, type, id, imgtag) {
		// External sites use tracking, so the tracking cookie needs to be enabled
		if (this.app.AVGController.AcceptedTrackingCookies) {
			return `
			<div class="videoContainer">
				<div class="video">
					<div class="videoPoster">
						${imgtag}
						<a title="Speel de video af">
							<svg class="sprite icon_play">
								<use xlink:href="#icon_play"></use>
							</svg>
						</a>
					</div>
					<iframe class="${type}" id="${id}" src="${url}" width="100%" height="100%" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
				</div>
			</div>`;
		} else {
			return `
			<div class="videoContainer notAccepted">
				<div class="content">
					<p class="title">Deze video heeft cookies nodig</p>
					<p>U heeft aangegeven liever geen cookies van YouTube of Vimeo te krijgen. Deze cookies zijn nodig om deze video te tonen.</p>
					<button onclick="javascript:window.app.AVGController.AgreeCookies();" class="button blue-white small">Ik ga akkoord</button>
				</div>
			</div>
			`;
		}
	}

	youtube(media) {
		const url = `https://www.youtube-nocookie.com/embed/${media.dataset.youtube}?wmode=opaque&rel=0&enablejsapi=1&origin=${window.location.protocol}//${window.location.hostname}`;

		let sourceRegex = /src="(.*?)"/;
		let imgsrc = media.innerHTML.match(sourceRegex);
		let imgElement = new Image();
		imgElement.src = imgsrc[1];

		const embed = this.embedIframe(url, "youtube", media.dataset.youtube, imgElement.outerHTML);

		let tempDiv = document.createElement('div');
		tempDiv.innerHTML = embed;

		media.before(tempDiv.firstElementChild);
		media.remove();
	}

	vimeo(media) {
		const url = `https://player.vimeo.com/video/${media.dataset.vimeo}?title=0&byline=0&portrait=0&api=1`;

		let sourceRegex = /src="(.*?)"/;
		let imgsrc = media.innerHTML.match(sourceRegex);
		let imgElement = new Image();
		imgElement.src = imgsrc[1];


		const embed = this.embedIframe(url, "vimeo", media.dataset.vimeo, imgElement.outerHTML);

		let tempDiv = document.createElement('div');
		tempDiv.innerHTML = embed;

		media.before(tempDiv.firstElementChild);
		media.remove();
	}

}