import Cookies from 'js-cookie';

export default class LikeController {
    constructor(autoinit = true) {
        this.cookie;
        this.selector = '.likes[data-like]';
        
        if (autoinit) {
            this.init();
        }
    }

    init() {
        if (document.querySelector(this.selector)) {
            this.cookie = Cookies.getJSON('likes');
            if (this.cookie === undefined) {
                this.cookie = [];
                Cookies.set('likes', this.cookie, {
                    expires: 99999,
                });
            }

            this.checkLikes();
        }
    }

    setEventListeners(likeContainer) {
        const pageId = parseInt(likeContainer.dataset.like);

        likeContainer.querySelector('.icon').addEventListener('click', () => {
    
            document.querySelectorAll(this.selector).forEach(container => {
                container.classList.add('liked');
                container.querySelector('.icon span').innerHTML = `${parseInt(container.querySelector('.icon span').innerHTML) + 1}`;
            });

            
            fetch(`/umbraco/api/content/like?pageId=${pageId}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({pageId: pageId})
            }).then(response => {
                if (response.ok) {
                    response.text().then(result => {
                        if (result == 'true') {
                            this.cookie.push(pageId);
                            Cookies.set('likes', this.cookie, {
                                expires: 99999,
                            });
                        }
                    });
                }
            });
        });
    }

    checkLikes() {
        document.querySelectorAll(this.selector).forEach(likeContainer => {
            const pageId = parseInt(likeContainer.dataset.like);

            if (this.cookie.includes(pageId)) {
                likeContainer.classList.add('alreadyLiked');
                likeContainer.classList.add('liked');
            } else {
                this.setEventListeners(likeContainer);
            }
        });
    }
}